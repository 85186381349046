import { useCallback, useEffect, useRef } from 'react';
import { MutableRefObject } from 'react';

export function useTimeout(callback: Function, delay: number) {
	const callbackRef: MutableRefObject<Function> = useRef(callback);
	const timeoutRef: MutableRefObject<number|undefined> = useRef();

	useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	// https://beta.reactjs.org/apis/react/useCallback
	const set = useCallback(() => {
		timeoutRef.current = window.setTimeout(() => callbackRef.current(), delay);
	}, [delay]);

	const clear = useCallback(() => {
		timeoutRef.current && window.clearTimeout(timeoutRef.current);
	}, []);

	useEffect(() => {
		set()
		return clear;
	}, [delay, set, clear]);

	const reset = useCallback(() => {
		clear()
		set();
	}, [clear, set]);

	return [ reset, clear ];
}
