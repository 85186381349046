import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-solid-svg-icons';

import RoomService from '../services/room-service';
import AuthService from '../services/auth-service';

import MediaUploadButton from './media/upload-button';
import ImageManager from "./imagesManager";
import MediaWithSharedPointer from "./mediaWithSharedPointer";


function searchSelectedMedia(roomId, medias, mediaId) {
	const mediaUrl = `${process.env.REACT_APP_LUMYS_API_URL}room/${roomId}/files/${mediaId}`;
	for (const key of Object.keys(medias)) {
		for (const media of medias[key]) {
			if (media.id == mediaId) return {
				name: media.name,
				source: media.type === 'dicom' ? mediaUrl + '/png' : mediaUrl,
				type: media.type,
			};
		}
	}
}


export default function MediaDisplay(props) {
	const [modalState, setModalState] = useState("modal");
	const [room, setRoom] = useState({});
	const [medias, setMedias] = useState({
		models: [],
		videos : [],
		images: [],
		pdfs: [],
		dicoms: [],
		others: [],
	});
	const [selectedMediaId, setSelectedMediaId] = useState(null);
	const [selectedMedia, setSelectedMedia] = useState({
		name: "",
		source: "",
		type: ""
	});

  const [message, setMessage] = useState();
  // TODO(sylvain): replace me with real url instead of get param !
  const url = new URL(window.location);
  const roomId = url.searchParams.get('id');

	async function loadRoomMedias() {
		RoomService.getFiles(roomId).then(response => {
			setMedias(RoomService.processGetFilesResponse(response.data));
		});
	}

	useEffect(() => {
		async function getRoom() {
			const room_response = await RoomService.getRoom(roomId);
			setRoom(room_response.data.room);
		}
		getRoom();

		loadRoomMedias();

		props.ws.addEventListener("message", (event) => {
			const message = JSON.parse(event.data);
			setMessage(message);
		});
	}, []);

	useEffect(() => {
		if (message) {
			switch (message.request) {
				case "shared-media":
					const data = JSON.parse(message.data);
					setSelectedMediaId(data.fileId);
					break;
				case "new-media":
				case "remove-media":
					loadRoomMedias();
					break;
			}
		}
	}, [message]);

	useEffect(() => {
		let newSelectedMedia = searchSelectedMedia(roomId, medias, selectedMediaId);
		if (newSelectedMedia) {
			setSelectedMedia(newSelectedMedia);
		} else {
			console.error(`ERROR: file with id '${selectedMediaId}' not found. Has media list changed ?`);
		}
	}, [selectedMediaId, medias]);

	function onMediaSelectedCallback(newSelectedMediaId, propagateMessage) {
		setSelectedMediaId(newSelectedMediaId);

		if (propagateMessage &&
			newSelectedMediaId !== null &&
			newSelectedMediaId !== undefined
		) {
			props.ws.send(JSON.stringify({
				request: "shared-media",
				roomId: roomId,
				data: JSON.stringify({ fileId: newSelectedMediaId, }),
			}));
		}

		closeModal();
	}

	function closeModal() {
		setModalState("modal");
	}

	return (
		<div className="">
			<nav className="level">
				{room && AuthService.canEditRoom(room) && <MediaUploadButton room_id={roomId}/>}
				<button className="button" onClick={() => { setModalState("modal is-active"); }}>
					<span className="text-icon">
						<span className="icon">
							<FontAwesomeIcon icon={faImages} />
						</span>
						<span>Select Media</span>
					</span>
				</button>
			</nav>

			<div className={modalState}>
				<div className="modal-background" onClick={closeModal}></div>
				<div className="modal-content">
					<div className="box">
						<ImageManager
							room_id={roomId}
							ws={props.ws}
							medias={medias}
							mediaSelectedCallback={onMediaSelectedCallback}
						/>
					</div>
				</div>
				<button
					className="modal-close is-large"
					aria-label="close"
					onClick={closeModal}
				></button>
			</div>
			{(selectedMedia.name !== "") && <MediaWithSharedPointer
				src={selectedMedia.source}
				type={selectedMedia.type}
				ws={props.ws}
			/>}
    </div>
  );
}
