import React, { useState } from "react";

function MessageSlate(props) {
    const [username, setUsername] = useState(null);

    return (
        <div className="container title-message">
            <div className="level">
                <div className="level-left">
                    <div className="level-item">
                        <figure className="image is-32x32">
                            <img className="is-rounded" src="https://bulma.io/images/placeholders/128x128.png" alt="user profile"/>
                        </figure>
                    </div>
                    <div className="level-item"><strong>{props.username}</strong></div>
                </div>
            </div>
        </div>
    );
}

export default MessageSlate;