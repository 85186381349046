import axios from "axios";

const axios_instance = axios.create({
  baseURL: `${process.env.REACT_APP_LUMYS_API_URL}`,
  withCredentials: true
});

axios_instance.interceptors.response.use(
  (response) => response, // NOTE(sylvain): do nothing, we just want to touch error case
  function (error) {
    if (error.response && error.response.status === 401) {
      window.location = '/login';
        // NOTE(sylvain): should still reject Promise afterwards ?
    }
    return Promise.reject(error);
  }
);

export default axios_instance;
