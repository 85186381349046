import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as BABYLON from "@babylonjs/core";
import "@babylonjs/loaders";

import RoomService from "../services/room-service";
import SceneComponent from "../components/sceneComponent";


export default function ModelPreview() {
  const { id } = useParams();
  const { model_id } = useParams();
  const [_, setScene] = useState(null);

	function findParentMesh(scene) {
		for (const mesh of scene.meshes) {
			if (mesh.parent && mesh.parent.name === "__root__") return mesh;
		}
	}

  function onSceneReady(scene) {
    const camera = new BABYLON.ArcRotateCamera(
      'Camera',
      -Math.PI / 2,
      Math.PI / 2,
      100.0,
      new BABYLON.Vector3(0, 0, 0),
      scene
    );
    camera.lowerBetaLimit = null;
    camera.upperBetaLimit = null;
    camera.allowUpsideDown = true;
    camera.noRotationConstraint = true;
    camera.panningSensibility = 0;
    camera.attachControl(scene.getEngine().getRenderingCanvas(), true);
    new BABYLON.HemisphericLight('ambient', new BABYLON.Vector3(1, 1, 0), scene);
    new BABYLON.PointLight('point', new BABYLON.Vector3(0, 1, -1), scene);
    scene.getEngine().resize();

    BABYLON.SceneLoader.ShowLoadingScreen = false;
    scene.getEngine().displayLoadingUI();

    RoomService.getFile(id, model_id).then((response) => {
      const model_blob = new Blob([response.data]);
      // NOTE(sylvain): babylonjs REQUIRES a name with a valid extention to
      // know which loader to use on the file. Since I haven't found an elegant
      // way to pass this information down there for now, we can only preview
      // gltf files :)
      const model_file = new File([model_blob], "pouet.glb");

      scene.getEngine().hideLoadingUI();

			BABYLON.SceneLoader.Append('file:', model_file, scene, () => {
				let parentMesh = findParentMesh(scene);
				parentMesh.scaling.x = 1;
				parentMesh.scaling.y = 1;
				parentMesh.scaling.z = 1;
				const meshBoudingSphere = parentMesh.getBoundingInfo().boundingSphere;
				const maxRadius = meshBoudingSphere.radius * 8;
				const maxZoomRatio = 100;
				camera.upperRadiusLimit = maxRadius;
				camera.lowerRadiusLimit = maxRadius / maxZoomRatio;
				camera.radius = maxRadius;
				camera.inputs.attached.mousewheel.wheelPrecision = maxZoomRatio / meshBoudingSphere.radius;
				setScene(scene);
			});
		});
  }

  return <SceneComponent antialias onSceneReady={onSceneReady} id="my-canvas" />;
}
