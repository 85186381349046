import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import NavBar from "../components/navbar";
import RoomForm from "../components/roomForm";
import SearchUserForm from '../components/searchUserForm';
import RoomService from "../services/room-service"
import UserService from "../services/user-service";
import { Breadcrumb } from "../components/breadcrumb";
import MediaTable from '../components/media/table';
import LogoutTimer from "../components/LogoutTimer";

const ERROR_MESSAGE_RETRIEVE_DATA = "An error occured while trying to retrieve data for this page. If the error occurs again, please contact Lumys' tech support.";
const ERROR_MESSAGE_SENDING_DATA = "An error occured while trying to send data for this page. If the error occurs again, please contact Lumys' tech support.";


function UserTable({ users, onDeleteUser }) {
	return (
		<table className="table is-fullwidth is-hoverable is-narrow">
			<tbody>
				{users && users.map(user => (
					<tr key={user.id}>
						<td>{user.name}</td>
						<td className="level-right">
							<button className="button is-small is-danger" onClick={() => { onDeleteUser(user.id) }}>
								<FontAwesomeIcon icon={faXmark} />
							</button>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}

export default function EditPage() {
	const page_name = "Edit a room";

	const { id } = useParams();
	const [room, setRoom] = useState(null);
	const [participants, setParticipants] = useState([]);
	const [editors, setEditors] = useState([]);

	useEffect(() => {
		async function getRoom() {
			try {
				const res_room = await RoomService.getRoom(id);
				setRoom(res_room.data.room);

				if (res_room.data.room.participants.length > 0) {
					const res_participants = await UserService.getUsers({
						id: res_room.data.room.participants
					})
					setParticipants(res_participants.data.users ?? []);
				}

				if (res_room.data.room.editors.length > 0) {
					const res_editors = await UserService.getUsers({
						id: res_room.data.room.editors
					})
					setEditors(res_editors.data.users ?? []);
				}
			} catch (error) {
				console.error(error);
				alert(ERROR_MESSAGE_RETRIEVE_DATA);
			}
		}
		getRoom();
	}, [id]);

	function onChangeCallback(name) {
		room.name = name;
		setRoom({ ...room });
	}

	// TODO(sylvain): should put participants and editors handling in their own
	// respective component ? Or in a generic component that works for both.
	async function onSearchParticipantFormResult(searchResult) {
		const newParticipants = [...participants, searchResult];
		const filteredNewParticipants = newParticipants.filter((participant, index, arr) => {
			return arr.map(mapParticipant => mapParticipant.id).indexOf(participant.id) === index
		});
		const filteredNewParticipantsIds = filteredNewParticipants.map(p => p.id);
		try {
			const response = await RoomService.patchRoom(id, { participants: filteredNewParticipantsIds });
			setParticipants(filteredNewParticipants);
		} catch (error) {
			alert(ERROR_MESSAGE_SENDING_DATA);
		}
	}

	async function onDeleteParticipant(participantId) {
		const filteredParticipants = participants.filter(p => p.id !== participantId)
		const filteredParticipantsIds = filteredParticipants.map(p => p.id)
		try {
			const response = await RoomService.patchRoom(id, { participants: filteredParticipantsIds });
			setParticipants(filteredParticipants);
		} catch (error) {
			alert(ERROR_MESSAGE_SENDING_DATA);
		}
	}

	async function onSearchEditorFormResult(searchResult) {
		const newEditors = [...editors, searchResult];
		const filteredNewEditors = newEditors.filter((editor, index, arr) => {
			return arr.map(mapEditor => mapEditor.id).indexOf(editor.id) === index
		});
		const filteredNewEditorwsIds = filteredNewEditors.map(p => p.id);
		try {
			const response = await RoomService.patchRoom(id, { editors: filteredNewEditorwsIds });
			setEditors(filteredNewEditors);
		} catch (error) {
			alert(ERROR_MESSAGE_SENDING_DATA);
		}
	}

	async function onDeleteEditor(editorId) {
		const filteredEditors = editors.filter(p => p.id !== editorId)
		const filteredEditorsIds = filteredEditors.map(p => p.id)
		try {
			const response = await RoomService.patchRoom(id, { editors: filteredEditorsIds });
			setEditors(filteredEditors);
		} catch (error) {
			alert(ERROR_MESSAGE_SENDING_DATA);
		}
	}

	return (
		<>
			<LogoutTimer></LogoutTimer>
			<NavBar></NavBar>
			<Breadcrumb currentPage={page_name} />

			<div className="container ">
				<h1 className="title is-h1">{page_name}</h1>

				<div className="container has-text-centered">
					<section className="section-slim">
						<div className="card">
							<header className="card-header">
								<p className="card-header-title">Room "{room?.name}"</p>
							</header>
							<div className="card-content">
								<div className="content">
									{room && <RoomForm room={room} onChangeCallback={onChangeCallback} />}
								</div>
							</div>
						</div>
					</section>

					<section className="section-slim">
						<div className="columns">
							<div className="column is-6">
								<div className="card">
									<header className="card-header">
										<p className="card-header-title">Participants</p>
									</header>
									<div className="card-content">
										<div className="content">
											<SearchUserForm onResultCallback={onSearchParticipantFormResult} />
											<UserTable users={participants} onDeleteUser={onDeleteParticipant} />
										</div>
									</div>
								</div>
							</div>

							<div className="column is-6">
								<div className="card">
									<header className="card-header">
										<p className="card-header-title">Editors</p>
									</header>
									<div className="card-content">
										<div className="content">
											<SearchUserForm onResultCallback={onSearchEditorFormResult} />
											<UserTable users={editors} onDeleteUser={onDeleteEditor} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="section-slim">
						<div className="card">
							<header className="card-header">
								<p className="card-header-title">Manage Medias</p>
							</header>
							<div className="card-content">
								<div className="content">
									<MediaTable room_id={id} />
								</div>
							</div>
						</div>
					</section>

				</div>
			</div>
		</>
	);
}
