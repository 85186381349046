import React, { useEffect, useRef, useState, useCallback } from "react";
import MessageSlate from "./messageSlate";
import Message from "./message-f.tsx";
import { sendMessage } from "../room/roomSystem";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'

import "../css/scroll.scss";

function NewChat(props){
    const [inputMessage, setInputMessage] = useState("");
    const [messages, setMessages] = useState([]);

    const scrollRef = useRef();

    useEffect(() => {
        setMessages(props.messages);
    },[props.messages])

    useEffect(() => {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    },[messages])

    function postMessage(event) {
        event.preventDefault();
        let message = inputMessage;
        message = message.replace(/^\s+/g, "");
        if (message !== "") {
          sendMessage(message);
        }
        setInputMessage("");
    }

    function SetChatSlate(element) {
      let array = element.element;
      let lastUser = "";
      const listMsg = array.map((el) => (
        <div key={el.date}>
          {lastUser !== el.username && (
            <MessageSlate username={(lastUser = el.username)} />
          )}
          <div className="has-background-primary-light">
            <Message message={el.message} date={new Date(el.date)} />
          </div>
        </div>
      ));
      return listMsg;
    }

    return (
        <div className="container box is-info" style={{position:"absolute", left:"-12em", top:"-24em"}}>
          <div className="chat-messages" id="chat-messages" ref={scrollRef}>
            <SetChatSlate element={messages}></SetChatSlate>
          </div>
          <form onSubmit={(e)=>{ postMessage(e);}}>
            <div className="field has-addons">
              <input
                className="input"
                type="text"
                value={inputMessage}
                onChange={(event)=> setInputMessage(event.target.value)}
              />
              <p className="control">
                <button className="button is-success">
                  <span className="icon">
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </span>
                </button>
              </p>
            </div>
          </form>
        </div>
      );
}
export default NewChat;