import { useEffect, useRef, useState } from "react";
import PointerManager from "./pointerManager";
import { getRoomId } from "../room/roomSystem";
import AuthService from "../services/auth-service";


export default function MediaWithSharedPointer(props) {
  const refVideo = useRef();
  const refImage = useRef();
  const [focus, setFocus]= useState(false);
  const [currentTime, setCurrentTime] = useState();

  useEffect(() => {
    if (currentTime) sendTimeCode(currentTime);
  }, [currentTime]);

  useEffect(() => {
    if (focus) sendTimeCode(currentTime);
  }, [focus]);
  
  function mouseInteraction(event) {
    const nmp = getNormalizedMousePosition(event);
    sendSharedNormalizedPoint(nmp);
  }

  function sendSharedNormalizedPoint(nmp) {
    props.ws.send(JSON.stringify({
      request: "shared-normalized-point",
      roomId: getRoomId(),
      data: JSON.stringify({
        x: nmp.x,
        y: nmp.y,
        username: AuthService.getCurrentUser().userName,
        peerId: AuthService.getCurrentUser().userId
      }),
    }));
  }

  function updateTime(event) {
    setCurrentTime(event.target.currentTime);
  }

  function sendTimeCode(timecode) {
    if (focus) {
      props.ws.send(JSON.stringify({
        request: "timecode",
        roomId: getRoomId(),
        data: JSON.stringify({
          username: AuthService.getCurrentUser().userName,
          peerId:AuthService.getCurrentUser().userId,
          timecode
        }),
      }));
    }
  }

  function getNormalizedMousePosition(event, target) {
    target = target || event.target;
    const pos = getRelativeMousePosition(event, target);

    pos.x = pos.x / target.clientWidth;
    pos.y = pos.y / target.clientHeight;

    return pos;
  }

  function getRelativeMousePosition(event, target) {
    target = target || event.target;
    const rect = target.getBoundingClientRect();

    return {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    };
  }

  function cursorLeft() {
    setFocus(false);
    props.ws.send(JSON.stringify(
      {
        request:"cursor-left",
        roomId: getRoomId(),
        peerId:  AuthService.getCurrentUser().userId,
      }
    ))
  }

  function cursorEnter() {
    setFocus(true);
  }

  return (
    <div className="container">
      <div style={{ postion: "relative" }} id="shared-container">
        {props.type === 'video' && <div>
          <video
            type="video/mp4"
            ref={refVideo}
            src={props.src}
            muted
            onMouseMove={mouseInteraction}
            onMouseLeave={cursorLeft}
            onMouseEnter={cursorEnter}
            onTimeUpdate={(e) => updateTime(e)}
            autoPlay
            controls
          />
          <PointerManager
            ws={props.ws}
            refContainer={refVideo}
            request={"shared-normalized-point"}
            type={props.type}
          />
        </div>}
        {(props.type === 'image' || props.type === 'dicom') && <div>
          <img
            ref={refImage}
            src={props.src}
            alt=""
            draggable={false}
            onMouseMove={mouseInteraction}
            onMouseLeave={cursorLeft}
          />
          <PointerManager
            ws={props.ws}
            refContainer={refImage}
            request={"shared-normalized-point"}
            type={props.type}
          />
        </div>}
      </div>
    </div>
  );
}
