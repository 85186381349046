import axios_instance from './base'
import AuthService from "./auth-service";


const UserService = {
  signup: function(data) {
    return axios_instance.post(`/user/signup`, data);
  },

  getUser: function(userId) {
    if (userId === undefined) userId = AuthService.getCurrentUser().userId;
    return axios_instance.get(`/user/${userId}`);
  },

  /**
    filters:
    name (string, unique)
    limit (number, unique)
    page (number, unique)
    id (object id, repeatable)
   */
  getUsers: function(filters) {
    // NOTE(sylvain): We should decide what the role of a "service" is, really :/
    // cf https://axios-http.com/docs/urlencoded
    // cf https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
    // cf https://rapidapi.com/guides/send-query-parameters-axios
    const query_params = new URLSearchParams();
    for (const [key, value] of Object.entries(filters)) {
      if (Array.isArray(value)) {
        for (const val of value) query_params.append(key, val);
      } else {
        query_params.append(key, value);
      }
    }
    const query_string = query_params.toString();
    // return axios_instance.get(`/user/${query_string ? '?' + query_string : ''}`);
    return axios_instance.get(`/user/${query_string ? '?' + query_string : ''}`);
  },
}

export default UserService;
