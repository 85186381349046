import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
//import { useLocation } from "react-router-dom";

export function Breadcrumb(props) {
  //const location = useLocation();
  //const paths = location.pathname.split("/")
  //const currentPage = paths[paths.length -2] + " " + paths[1]

  return (
    <nav className="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li><a href="/"><span className="icon"><FontAwesomeIcon icon={faHome}/></span>Rooms</a></li>
        {props.roomName && <li><span className="roomName ml-2"> {props.roomName}</span></li>}
      </ul>
    </nav>
  )
}
