import React from "react";

import LoginForm from "../components/login-f.tsx";
import logo from "../images/Logo-lumys-nom.png";

class LoginPage extends React.Component {
  render() {
    return (
      <div className="hero-body">
        <div className="container has-text-centered">
          <div className="column is-4 is-offset-4">
            <figure className="image is-fullwidth">
              <img src={logo} alt="Logo" />
            </figure>
            <LoginForm />
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;
