import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons'
import "@creativebulma/bulma-badge/dist/bulma-badge.css"
import NewChat from "./newChat";
import "../css/scroll.scss";
import { getMessagesHistory } from "../room/roomSystem";

import AudioNotif from "../audio/message_alerte.mp3";

export default function ChatView(props) {

  const [isActive, setIsActive] = useState(false);
  const [messages, setMessages] = useState([]);
  const [unread, setUnread] = useState(0);
  const [newMessage, setNewMessage] = useState(null);

  const audioRef = useRef();

  useEffect(() => {
    props.ws.addEventListener("message", onNewMessage);
    getMessagesHistory();
  },[]);

  useEffect(() => {
    if (newMessage) {
      switch(newMessage.request){
        case 'message':
          addNewMessage(JSON.parse(newMessage.data));
          break;
        case 'messages-history':
          
          setHistory(JSON.parse(newMessage.data));
          break;
      }
    }
  },[newMessage]);

  function onNewMessage(message){
    const data = JSON.parse(message.data);
    if(data.request === "message" || data.request === "messages-history"){
      setNewMessage(data);
    }
  }

  function addNewMessage(message){
    if(!isActive){
      setUnread(unread+1);
      notifMessage();
    }
    setMessages([...messages,message]);
  }

  function setHistory(history){
    setMessages(history.messages);
  }

  function notifMessage(){
    audioRef.current.src= AudioNotif;
    audioRef.current.play();
  }

  return (
    <div>
      <audio ref={audioRef} playsInline/>
      {props.ws && isActive && <NewChat ws={props.ws} messages={messages}/>}
      {!isActive && <button
        className="button is-info is-light is-rounded tooltip"
        data-tooltip="Open chat"
        onClick={() => {setIsActive(true); setUnread(0);} }
      >
        <span className="icon"> 
          <FontAwesomeIcon icon={faCommentAlt} />
          {unread > 0 && <span title="is-top-right" className="badge is-top-right">{unread}</span>}
        </span>
      </button>}
      {isActive && <button
        className="button is-info is-rounded"
        onClick={() => setIsActive(false)}
      >
        <span className="icon">
      <FontAwesomeIcon icon={faCommentAlt} />
        </span>
      </button>}
    </div>
  );
}
