import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import UserService from '../services/user-service';
import useDebounce from "../hooks/useDebounce";


export default function SearchUserForm(props) {
	const [userField, setUserField] = useState('');
	const [users, setUsers] = useState([]);
	const [dropDownClassName, setDropdownClassName] = useState('dropdown')

	useDebounce(async () => {
		if (userField.length === 0) setUsers([]);
		if (userField.length < 3) return;

		const response = await UserService.getUsers({ name: userField });
		setUsers(response.data.users);
	}, 333, [userField]);

	useEffect(() => {
		users.length > 0 ? ShowDropdown() : HideDropdown();
	}, [users])

	function onUserFieldChange(e) {
		setUserField(e.target.value)
	}

	function onClickSearchResult(searchResult) {
		if (props.onResultCallback) props.onResultCallback(searchResult);
		setUserField("");
		setUsers([]);
	}

	function ShowDropdown() {
		setDropdownClassName('dropdown is-active');
	}

	function HideDropdown() {
		setDropdownClassName('dropdown');
	}

	return (
		<div className={dropDownClassName}>
			<div className="dropdown-trigger">
				<div className="field">
					<p className="control is-expanded has-icons-left">
						<span className="icon is-small is-left">
							<FontAwesomeIcon icon={faSearch} />
						</span>
						<input
								className="input"
								type="text"
								placeholder="search by name"
								name="username"
								autoComplete="off"
								value={userField}
								onChange={onUserFieldChange}
							/>
					</p>
				</div>
			</div>
			<div className="dropdown-menu" id="dropdown-menu" role="menu">
				<div className="dropdown-content">
					{users && users.map(u => (
						<a key={u.id} className="dropdown-item" onClick={() => {onClickSearchResult(u)}}>{u.name}</a>
					))}
				</div>
			</div>
		</div>
	);
}
