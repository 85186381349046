import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "bulma/css/bulma.min.css";

import "./index.css";
import HomePage from "./routes/home";
import LoginPage from "./routes/login";
import SignupPage from "./routes/signup";
import RoomPage from "./routes/room";
import EditPage from "./routes/edit";
import ModelPreview from "./routes/modelPreview"
import NoMatch from"./routes/noMatch";

// -----------------------------------------------------------------------------
// Styles sheets
import "./css/styles.scss";


ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/room" element={<RoomPage />} />
      {/* TODO(sylvain): we should have this link instead of dirty id in query params: */}
      {/* <Route path="/room/:id" element={<RoomPage />} /> */}
      <Route path="/room/:id/edit" element={<EditPage />} />
      <Route path="/room/:id/edit/preview/:model_id" element={<ModelPreview />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
