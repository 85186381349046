import { render } from "@testing-library/react";
import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons'

import WaitingScreen from "../images/WaitingForLD.png";
import {  getRoomId, sendNormalizedPoint } from "../room/roomSystem";
import PointerManager from "./pointerManager";
import AuthService from "../services/auth-service";

import "../css/videoDisplayer.scss";

function LargeDisplay(props) {
  const [ws, setWebsocket] = useState(props.ws);
  const refContainer = useRef("largedisplay");
  const refDisplayer = useRef("displayer");
  const refFullScreen = useRef("bfs");

  useEffect(() => {
    refContainer.current.srcObject = props.media;
  },[props.media]);

  function mouseInteraction(event) {
    let nmp = getNormalizedMousePosition(event);
    sendNormalizedPoint(nmp);
  }

  function getNormalizedMousePosition(event, target) {
    target = target || event.target;
    var pos = getRelativeMousePosition(event, target);

    pos.x = pos.x / target.clientWidth;
    pos.y = pos.y / target.clientHeight;

    return pos;
  }

  function getRelativeMousePosition(event, target) {
    target = target || event.target;
    var rect = target.getBoundingClientRect();

    return {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    };
  }

  function setFullScreen() {
    if (!document.fullscreenElement) {
      refDisplayer.current.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  function cursorLeft() {
    ws.send(JSON.stringify({
      request:"cursor-left",
      roomId: getRoomId(),
      peerId:  AuthService.getCurrentUser().userId,
    }));
  }

  return (
    <div>
      <div className="container">
        <div id="largedisplay-container" ref={refDisplayer}>
          <video
            id="largedisplay"
            ref={refContainer}
            width="1920"
            height="1080"
            muted
            autoPlay
            playsInline
            onMouseMove={mouseInteraction}
            onMouseLeave={cursorLeft}
            poster={WaitingScreen}
          ></video>
          <button
            id="fullscreen-button"
            className="button is-white"
            onClick={setFullScreen}
            ref={refFullScreen}
          >
            <span className="icon">
              <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
            </span>
          </button>
          <PointerManager ws={ws} refContainer={refContainer} request={"normalized-point"} type={"image"}/>
        </div>
      </div>
    </div>
  );
}
export default LargeDisplay;
