import React, { useEffect, useState, useRef } from "react";
import Cursor from "./cursor";

export default function PointerManager(props){

    const [pointers, setPointers] = useState(new Map());

    useEffect(() => {
        props.ws.addEventListener("message", newPointerPos);
    }, []);

    function newPointerPos(event) {
        const msg = JSON.parse(event.data);
        if (props.refContainer.current && msg.request === props.request) {
            const pointer = JSON.parse(msg.data);
            const bbox = props.refContainer.current.getBoundingClientRect();
            if (pointers.has(pointer.peerId)) {
                const p = pointers.get(pointer.peerId);
                p.x = bbox.width * pointer.x;
                p.y = bbox.height * pointer.y;
                pointers.set(pointer.peerId, p);
                setPointers(new Map(pointers));
            } else {
                setPointers(
                    new Map(
                        pointers.set(
                        pointer.peerId,
                        {
                            x: bbox.width * pointer.x,
                            y: bbox.height * pointer.y,
                            username: pointer.username,
                            timecode: null
                        }
                        )
                    )
                );
            }
            
        }
        else if (msg.request === "cursor-left"){
            const peerId = JSON.parse(msg.data).peerId;
            if (pointers.has(peerId)) {
                const newmap = pointers;
                newmap.delete(peerId);
                setPointers(new Map(pointers));
            }
        }
        else if (msg.request === "timecode"){
            const peerId = JSON.parse(msg.data).peerId;
            if(pointers.has(peerId)){
                const timecode = JSON.parse(msg.data).timecode;
                const pointer = pointers.get(peerId);
                pointer.timecode = timecode;
                pointers.set(peerId, pointer);
                setPointers(new Map(pointers));
            }
        }
    }

    return(
        <div>
            {[...pointers.keys()].map((k) => <Cursor
                key={k}
                username={pointers.get(k).username}
                x={pointers.get(k).x}
                y={pointers.get(k).y}
                timecode={pointers.get(k).timecode}
                type={props.type}
            />)}
        </div>
    );
}