import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone } from '@fortawesome/free-solid-svg-icons'

function LinkModal(props) {
  const [tooltipText, setTooltipText] = useState("Click to copy");
  const refInput = useRef("refInput");

  function copyToClipboard() {
    navigator.clipboard.writeText(refInput.current.value);
    setTooltipText("Copied !");
  }

  function switchState() {
    props.switchState();
  }

  return (
    <div className={props.active ? 'modal is-active' : 'modal'}>
      <div className="modal-background" onClick={() => switchState()}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Room's Link</p>
          <button className="delete" aria-label="close" onClick={() => switchState()}></button>
        </header>
        <section className="modal-card-body" style={{ padding: 40 }}>
          <div className="container">
            <div className="level field is-grouped">
              <input
                className="input"
                type="text"
                placeholder="Room Link"
                value={`https://${window.location.host}/room/?id=${props.id}`}
                id="roomLink"
                ref={refInput}
                readOnly
              />
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-success" onClick={() => copyToClipboard()}>Copy link</button>
          <button className="button" onClick={() => switchState()}>Cancel</button>
        </footer>
      </div>
    </div>
  );
}

export default LinkModal;
