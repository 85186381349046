import { NavigateFunction, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from 'react';
import "@creativebulma/bulma-tooltip/dist/bulma-tooltip.css";

import AuthService from '../services/auth-service';

export default function LogoutTimer() {
	const TIMER_IN_MS = 1000 * 60 * 15; // session expires after 15 minutes
	const LOGOUT_WARNING_TIME = 1000 * 10;
	const events: Array<string> = ['mousemove', 'mousedown', 'keydown'];
	const [expiredTime, setExpiredTime] = useState(Date.now() + TIMER_IN_MS);
	const [isTimeOut, setIsTimeOut] = useState(false);
	const [isActive, setIsActive] = useState(false);
	const navigate: NavigateFunction = useNavigate();
	const timerOut = useRef<number>();
	const interval = useRef<number>();

	useEffect(() => {
		if(isActive) {
			window.clearTimeout(timerOut.current);
			setIsTimeOut(false);
			setIsActive(false);
			updateExpiredTime();
		}
	}, [isActive])

	useEffect(() => {
		if(isTimeOut) {
			timerOut.current = window.setTimeout(() => {
					logout();
			}, LOGOUT_WARNING_TIME);
		}
	}, [isTimeOut]);

	useEffect(() => {

		events.forEach(event => {
			window.addEventListener(event, updateExpiredTime);
		});

		updateExpiredTime();

		return(() => {
			events.forEach(event => {
				window.removeEventListener(event, updateExpiredTime);
			});
			window.clearInterval(interval.current);
			window.clearTimeout(timerOut.current);
		})
	}, [])
	

	function updateExpiredTime() {
		const newExpiredTime: number = Date.now() + TIMER_IN_MS;
		setExpiredTime(newExpiredTime);
	}

	useEffect(() => {
		interval.current = window.setInterval(() => {
			if(expiredTime < Date.now()) {
				setIsTimeOut(true);
				window.clearInterval(interval.current);
			}
		}, 1000);

		return( () => {
			window.clearInterval(interval.current);
		})
	}, [expiredTime])

	function logout() {
		AuthService.logout();
		navigate('/login');
		localStorage.clear();
	}

	return(
		<div className={isTimeOut ? "modal is-active" : "modal"}>
			<div className="modal-background"></div>
				<div className="modal-content shadow-2xl">
					<div className="box has-background-light has-border has-text-centered">
						<p className="mt-1">Your session expires soon, are you still here ?</p>
						<button className="button mt-2 is-info is-light is-rounded is-centered" onClick={(_e) => setIsActive(true)}>Yes</button>
					</div>
				</div>
		</div>
	);
}