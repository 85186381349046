import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightAndDownLeftFromCenter, faLocationDot, faCamera} from '@fortawesome/free-solid-svg-icons'

import WaitingScreen from "../images/WaitingForHololens.png";
import "../css/videoDisplayer.scss";
import { getRoomId } from "../room/roomSystem";
import AuthService from "../services/auth-service";


function HololensDisplay(props) {
  const refDisplayer = useRef("bfs");
  const refVideoDisplayer = useRef("videoDisplay");
  const [isInPointingState, setIsInPointingState] = useState(false);
  const [text, setText] = useState("");
  useEffect(() => {
    refVideoDisplayer.current.srcObject = props.media;
  },[props.media]);

  function setFullScreen() {
    if (!document.fullscreenElement) {
      refDisplayer.current.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  function handleInputChange(e) {
    setText(e.target.value);
  }

  function pointInHololens(e) {
    if(!isInPointingState) return;
    let boundingRect = e.target.getBoundingClientRect();
    let x = (e.clientX - boundingRect.left) / boundingRect.width;
    let y = (e.clientY - boundingRect.top)/ boundingRect.height;
    let data = {
      username: AuthService.getCurrentUser().userName,
      text: text,
      x,
      y
    };
    props.ws.send(
      JSON.stringify({
        request: "spatial-point",
        roomId: getRoomId(),
        data: JSON.stringify(data)
      })
    );
    setIsInPointingState(false);
    setText("");
  }

  function takeScreenshot(){
    props.ws.send(
      JSON.stringify({
        request: "screenshot",
        roomId: getRoomId(),
        data: ""
      })
    );
  }

  return (
    <div className="container">
      <div id="largedisplay-container" ref={refDisplayer}>
        <video
          id="hololens-display"
          ref={refVideoDisplayer}
          width="1920"
          height="1080"
          muted
          autoPlay
          playsInline
          poster={WaitingScreen}
          onMouseDown= {pointInHololens}
        ></video>
        <button
          id="fullscreen-button"
          className="button is-white"
          onClick={setFullScreen}
        >
          <span className="icon">
            <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
          </span>
        </button>
        {!isInPointingState && <button
          id="spatialpoint-button"
          className="button is-info is-light"
          data-tooltip="Add landmarks in HoloLens view."
          onClick={() => setIsInPointingState(true)}
        >
          <span className="icon">
            <FontAwesomeIcon icon={faLocationDot} />
          </span>
        </button>}
        {isInPointingState && <button
          id="spatialpoint-button"
          className="button is-info"
          data-tooltip="Stop landmark addition."
          onClick={() => setIsInPointingState(false)}
        >
          <span className="icon">
            <FontAwesomeIcon icon={faLocationDot} />
          </span>
        </button>}
        {isInPointingState && <div
          id="input-text">
          <div style={{display: "inline-flex"}} >
              <input
                className="input"
                type="text"
                maxLength="40"
                size="20"
                placeholder="Add text"
                value={text}
                onChange={handleInputChange}
              />
            </div>
        </div>}
        <button
          id="screenshot-button"
          className = "button is-white"
          onClick={takeScreenshot}
        >
          <span className="icon">
            <FontAwesomeIcon icon={faCamera} />
          </span>
        </button>
      </div>
    </div>
  );
}
export default HololensDisplay;
