import { useEffect } from 'react';

import { useTimeout } from './useTimeout';

// NOTE(sylvain): If you have a better example, please replace this one :)
// https://davidwalsh.name/javascript-debounce-function
export default function useDebounce(callback: Function, delay: number, dependencies: React.DependencyList) {
	const [reset, clear] = useTimeout(callback, delay);
	useEffect(reset, [...dependencies, reset]);
	useEffect(clear, []);
}
