import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMousePointer } from '@fortawesome/free-solid-svg-icons'


function Cursor(props) {
  const display = useState(props.state);
  const cursorRef = useRef(props.type + "-physicianCursor-" + props.username);
  const fontRef = useRef("iconPointer");

  const styleF = {
    width: "10px",
    position:"absolute",
  }
  useEffect(() => {
    /*const interval = setInterval(() => {
      cursorRef.current.style.display = "none";
    }, 2000);
    return () => clearInterval(interval);*/
  }, [props.x, props.y]);

  function convertTime(){
    const timecode = parseInt(props.timecode);
    const minutes = Math.floor(timecode/60);
    const secondes = timecode - minutes *60;
    return `${minutes}:${secondes}`;
  }

  return (
    <div
      id={props.type + "-physicianCursor-" + props.username}
      ref={cursorRef}
      className="is-pulled-left"
      style={{
        position: "absolute",
        left: props.x,
        top: props.y,
        display: display,
        color: "#63ff14"
      }}
    >
      <span className="icon is-small" style={styleF}>
        <FontAwesomeIcon inneref={fontRef} icon={faMousePointer} />
      </span>
      {props.timecode !== null && <span style={{
        position: "absolute",
        left: "8px",
        top: "8px",
      }}> {props.username + ` (${convertTime()})`} </span>}
      {props.timecode == null && <span style={{
        position: "absolute",
        left: "8px",
        top: "8px",
      }}> {props.username} </span>}
      
    </div>
  );
}
export default Cursor;
