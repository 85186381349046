import axios_instance from './base';


const RoomService = {
  getRooms: function() {
    return axios_instance.get(`/room/`);
  },

  createRoom: function(roomData) {
    return axios_instance.post(`/room/`, roomData);
  },

  getRoom: function(id) {
    return axios_instance.get(`/room/${id}`);
  },

  patchRoom: function(id, roomData) {
    return axios_instance.patch(`/room/${id}`, roomData);
  },

  deleteRoom: function(id) {
    return axios_instance.delete(`/room/${id}`);
  },

	getFiles: function(room_id) {
		return axios_instance.get(`/room/${room_id}/files/`);
	},

	processGetFilesResponse: function(data) {
		const isModel = file => file.mimetype.startsWith('model');
		const isVideo = file => file.mimetype.startsWith('video');
		const isImage = file => file.mimetype.startsWith('image');
		const isPdf = file => file.mimetype === 'application/pdf';
		const isDicom = file => file.mimetype === 'application/dicom';
		const isOther = file => !isModel(file) && !isVideo(file) && !isImage(file) && !isPdf(file) && !isDicom(file);

		function getSimpleType(file) {
			if (isModel(file)) {
				return 'model';
			} else if (isVideo(file)) {
				return 'video';
			} else if (isImage(file)) {
				return 'image';
			} else if (isPdf(file)) {
				return 'pdf';
			} else if (isDicom(file)) {
				return 'dicom';
			} else {
				return 'other';
			}
		}

		// TODO(sylvain): compute url and thumbnail url here ?
		const files = data.files.map(file => { return {
			id: file._id,
			name: file.filename,
			filename: file.filename,
			mimetype: file.metadata.mimetype,
			type: getSimpleType({filename: file.filename, mimetype: file.metadata.mimetype}),
		} });

		return {
				models: files.filter(isModel),
				videos: files.filter(isVideo),
				images: files.filter(isImage),
				pdfs: files.filter(isPdf),
				dicoms: files.filter(isDicom),
				others: files.filter(isOther),
		};
	},

	getFile: function(room_id, file_id) {
		return axios_instance.get(
			`/room/${room_id}/files/${file_id}`,
			{ responseType: 'blob' } // TODO(sylvain): required for babylonjs
		);
	},

	deleteFile: function(room_id, file_id) {
		return axios_instance.delete(`/room/${room_id}/files/${file_id}`);
	},

	uploadFiles: function(room_id, data, onUploadProgressCallback) {
		return axios_instance.post(`/room/${room_id}/files/`, data, { 
			headers: { "Content-Type": "multipart/form-data" },
			onUploadProgress: onUploadProgressCallback
		});
	},
}

export default RoomService;
