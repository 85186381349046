import React from "react";

type MessageProps = {
  message: string;
  date: Date;
};

export default function Message(props: MessageProps) {
  const date: string = props.date.getHours() + ":" + props.date.getMinutes();
  return (
    <div className="is-flex">
      <div className="message">{props.message}</div>
      <div className="date has-text-right">
        <p>{date}</p>
      </div>
    </div>
  );
}
