import React, { useEffect, useState } from "react";
import 'animate.css/animate.css';

import { PeerType } from "../lib/peer";
import AuthService from "../services/auth-service";
import {
	getCababilities, setRoomCapabilities, getRoomId,
	createSendTransport, tryJoinRoom } from "../room/roomSystem";

import Participant from "./participant";


const bot_constraints = {
  width: { exact: 1920 },
  height: { exact: 1080 },
};


// NOTE(sylvain): This file is ugly, it's a huge copy-pasted "conference.js"
// with most of it removed for the very specific case of being a large display.
export default function BroadcastOnly(props) {

	const [sendTransport, setSendTransport] = useState(null);
	const [dataReceived, setDataReceived]= useState(null);

	useEffect(() => {
		props.ws.addEventListener("message", setDataReceived);
		getCababilities();
		// TODO(sylvain): this can fail with overconstraints error sometimes,
		// for no apparent or good reason, not even repeatedly, retry multiple times ?
		tryToApplyConstraints();
	}, []);

	useEffect(() => {
		if (dataReceived !== null) receiveMessage(dataReceived);
	}, [dataReceived]);
	//NOTE(erwan): We dont want to send sound here, it cause some issue with hololens reconnection
	/*useEffect(() => {
		async function produceAudio() {
			await sendTransport.produce({
				track: props.localAudio.getAudioTracks()[0],
				// TODO(sylvain): server should know who's socket is who, never
				// trust the client !
				appData: { type: "largeDisplay", name: AuthService.getCurrentUser().userName },
			});
		}
		if (props.localAudio && sendTransport) {
			produceAudio();
		}
	}, [props.localAudio, sendTransport]);*/

	useEffect(() => {
		async function produceVideo() {
			await sendTransport.produce({
				track: props.localVideo.getVideoTracks()[0],
				// TODO(sylvain): server should know who's socket is who
				// never trust the client !
				appData: { type: "largeDisplay", name: AuthService.getCurrentUser().userName },
			});
		}
		if (props.localVideo && sendTransport ) {
			console.log("Creating video producer");
			produceVideo();
		}
	}, [props.localVideo, sendTransport]);

	async function tryToApplyConstraints() {
		const max_retries = 10;
		let attempts = 0;
		let has_error = true;
		let error;

		while (has_error && attempts < max_retries) {
			try {
				await props.localVideo.getVideoTracks()[0].applyConstraints(bot_constraints);
				has_error = false; // if we get here, no error.
			} catch (err) {
				if (err.name === "OverconstrainedError") error = err;
			}
			attempts += 1;
		}

		if (error) console.error(error);
	}

	function makeSendTransport({transportData}) {
		console.log(transportData);
		const transport = createSendTransport(transportData);

		transport.on("connect", async ({ dtlsParameters }, callback, _errback) => {
			props.ws.addEventListener("message", (message) => {
				const dataJson = JSON.parse(message.data);
				if (dataJson.request === "connect-transport") {
					callback();
				}
			});
			props.ws.send(JSON.stringify({
				request: "connect-transport",
				roomId: getRoomId(),
				peerId: AuthService.getCurrentUser().userId,
				transportId: transport.id,
				dtlsParameters,
			}));
		});

		transport.on("produce", async ({ kind, rtpParameters, appData }, callback, errback) => {
			console.log(
				"Transport::produce [kind:%s, rtpParameters:%o]",
				kind,
				rtpParameters,
				appData.type
			);

			props.ws.addEventListener("message", (message) => {
				try {
					const dataJson = JSON.parse(message.data);
					if (dataJson.request === "produce") {
						console.warn("produce");
						callback({ id: dataJson.producerData.id });
					}
				} catch (error) {
					console.error("Failed to handle transport connect", error);
					errback(error);
				}
			});
			console.log("Transport : ", transport.id, "sending/producing ", kind);
			props.ws.send(
				JSON.stringify({
					request: "produce",
					roomId: getRoomId(),
					peerId: AuthService.getCurrentUser().userId,
					transportId: transport.id,
					kind,
					rtpParameters,
					appData,
				})
			);
		});

		setSendTransport(transport);
	}

	function createTransportSend() {
			console.log("createTransport() creating send transport");
			props.ws.send(
				JSON.stringify({
					request: "create-transport",
					roomId: getRoomId(),
					peerId: AuthService.getCurrentUser().userId,
					type: "send",
				})
			);
	}

	async function receiveMessage(message) {
		const data = JSON.parse(message.data);
		switch(data.request) {
			case 'room-capabilities':
				setRoomCapabilities(data).then(() => {
					tryJoinRoom(PeerType.LARGE_DISPLAY);
				});
				break;
			case 'created-transport':
				if (data.type === "send") {
					makeSendTransport(data);
				}
				break;
			case 'room-joined':
				createTransportSend();
				break;
		}
	}

	return (
		<div className="is-flex is-half is-flex-direction-row is-justify-content-center is-align-items-flex-start">
			<Participant
				name="You"
				type={PeerType.LARGE_DISPLAY}
				isMute={props.isMute}
				videoSource={props.localVideo}
				audioSource={props.localAudio}
				isLocalUser={true}
				fullsize
			/>
		</div>
	);
}
