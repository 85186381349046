import { useEffect, useRef } from "react";
import { Engine, Scene } from "@babylonjs/core";


export default function SceneComponent(props) {
  const reactCanvas = useRef(null);
  const {
    antialias,
    engineOptions,
    adaptToDeviceRatio,
    sceneOptions,
    onRender,
    onSceneReady,
    ...rest
  } = props;

  function throttle(func, wait, leading, trailing, context) {
    var ctx, args, result;
    var timeout = null;
    var previous = 0;
    var later = function() {
        previous = new Date;
        timeout = null;
        result = func.apply(ctx, args);
    };

    return function() {
      var now = new Date;
      if (!previous && !leading) previous = now;
      var remaining = wait - (now - previous);
      ctx = context || this;
      args = arguments;
      // Si la période d'attente est écoulée
      if (remaining <= 0) {
          // Réinitialiser les compteurs
          clearTimeout(timeout);
          timeout = null;
          // Enregistrer le moment du dernier appel
          previous = now;
          // Appeler la fonction
          result = func.apply(ctx, args);
      } else if (!timeout && trailing) {
          // Sinon on s’endort pendant le temps restant
          timeout = setTimeout(later, remaining);
      }
      return result;
    };
  };

  useEffect(() => {
    if (reactCanvas.current) {
      const engine = new Engine(
        reactCanvas.current,
        antialias,
        engineOptions,
        adaptToDeviceRatio
      );
      const scene = new Scene(engine, sceneOptions);
      if (scene.isReady()) {
        props.onSceneReady(scene);
      } else {
        scene.onReadyObservable.addOnce((scene) => props.onSceneReady(scene));
      }
      engine.setHardwareScalingLevel(0.5);
      engine.runRenderLoop(() => {
        if (typeof onRender === "function") {
          onRender(scene);
        }
        scene.render();
      });

      const resize = () => {
        engine.resize();
      };

      if (window) {
        //window.addEventListener("resize", () => throttle(resize,500,true,true));
        //window.addEventListener("resize", resize);
      }

      return () => {
        scene.getEngine().dispose();

        /*if (window) {
          window.removeEventListener("resize", resize);
          //window.removeEventListener("resize", () => throttle(resize,500,true,true));
        }*/
      };
    }
  }, [reactCanvas]);

  return <canvas ref={reactCanvas} width="1920" height="1080" {...rest} />;
}
