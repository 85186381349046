import React from "react";
import Signup from "../components/signup";
import logo from "../images/Logo-lumys-nom.png";

class SignupPage extends React.Component {
  render() {
    return (
      <div className="hero-body">
        <div className="container has-text-centered">
          <div className="column is-4 is-offset-4">
            <figure className="image is-fullwidth">
              <img src={logo} alt="Logo" />
            </figure>
            <Signup></Signup>
          </div>
        </div>
      </div>
    );
  }
}

export default SignupPage;
