import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faCameraAlt, faMicrophone, faMicrophoneSlash,
	faRightToBracket, faRobot } from '@fortawesome/free-solid-svg-icons';
import "@creativebulma/bulma-tooltip/dist/bulma-tooltip.css";

import Participant from "./participant";

const MEDIA_DEVICE_TYPE = {
	audio: "microphone",
	video: "camera"
}

export default function RoomJoin(props) {

	const [hasAudioPermission, setHasAudioPermission] = useState(undefined);
	const [hasVideoPermission, setHasVideoPermission] = useState(undefined);

	const [devices, setDevices] = useState([]);


	useEffect(() => {
		async function askDevicePermission(mediaDeviceType, setHasPermissionFunc, setWillBeActiveFunc) {
			try {
				await navigator.mediaDevices.getUserMedia(
					mediaDeviceType == MEDIA_DEVICE_TYPE.audio ? {audio: true} : { video: {}}
				);
				setHasPermissionFunc(true);
			} catch (error) {
				setHasPermissionFunc(false);
				setWillBeActiveFunc(false);
				if (error.name === "NotAllowedError") {
					console.log(`Lumys cannot access your ${mediaDeviceType}, others will not be able to ${
						mediaDeviceType === MEDIA_DEVICE_TYPE.audio ? "hear" : "see"} you`);
				} else {
					alert(`Something went wrong; Impossible to start your ${mediaDeviceType}`);
				}
			}
		}

		askDevicePermission(MEDIA_DEVICE_TYPE.audio, setHasAudioPermission, props.onChangeAudioWillBeActive);
		askDevicePermission(MEDIA_DEVICE_TYPE.video, setHasVideoPermission, props.onChangeVideoWillBeActive);
	}, []);

	useEffect(() => {
		async function getAvailableDevices() {
			setDevices(await navigator.mediaDevices.enumerateDevices());
		}

		if (hasAudioPermission || hasVideoPermission) {
			getAvailableDevices();
		}

	}, [hasAudioPermission, hasVideoPermission]);

	useEffect(() => {
		// select first video and first audio as default video and audio stream
		// NOTE(sylvain): which is kind of a "problem" with firefox, since they're
		// actually doing things much more privatly for the end user. They only
		// allow device the user specifically allowed, but we can't know WHICH
		//  device has been allowed as far as I know. So if the user allow the
		// "second" device first, this will prompt the user again for the first
		// media device returned by enumerateDevices().
		const videoDevice = devices.find(device => device.kind === "videoinput");
		if (videoDevice) props.onChangeVideoDevice(videoDevice.deviceId);

		const audioDevice = devices.find(device => device.kind === "audioinput");
		if (audioDevice) props.onChangeAudioDevice(audioDevice.deviceId);
	}, [devices]);


	function hasGivenPermissions() {
		if (hasAudioPermission !== undefined && hasVideoPermission !== undefined) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<>
			<div className="columns">
				<section className="column">
					<Participant
						name="You"
						isMute={props.isMute}
						videoSource={props.localVideo}
						audioSource={props.localAudio}
						isLocalUser={true}
						fullsize
					/>
				</section>
				<section className="column is-half section box">
					<h1> Preparing to join call</h1>
					
					{!hasVideoPermission && <div className="message">
						<div className="message-body">
							Access to camera has not been granted, check you browser's permissions
						</div>
					</div>}
					{hasVideoPermission && <div className="field">
						<label className="field-label is-normal" htmlFor="select-video-track">Video source:</label>
						<div className="field-body">
							<div className="field">
								<div className="control has-icons-left">
									<div className="select is-rounded">
										<select
											name="select-video-track"
											id="videoTrack"
											onChange={(event) => props.onChangeVideoDevice(event.target.value)}
										>
											{devices.filter(device => device.kind === "videoinput")
												.map(device => <option key={device.deviceId} value={device.deviceId}>{device.label}</option>)
											}
										</select>
									</div>
									<div className="icon is-small is-left">
										<FontAwesomeIcon icon={faCameraAlt} />
									</div>
								</div>
							</div>
						</div>
					</div>}
					{!hasAudioPermission && <div className="message">
						<div className="message-body">
							Access to microphone has not been granted, check you browser's permissions
						</div>
					</div>}
					{hasAudioPermission && <div className="field">
						<label className="field-label is-normal" htmlFor="select-video-track">Audio source:</label>
						<div className="field-body">
							<div className="field">
								<div className="control has-icons-left">
									<div className="select is-rounded">
										<select
											name="select-audio-track"
											id="audioTrack"
											onChange={(event) => props.onChangeAudioDevice(event.target.value)}
										>
											{devices.filter(device => device.kind === "audioinput")
												.map(device => <option key={device.deviceId} value={device.deviceId}>{device.label}</option>)
											}
										</select>
									</div>
									<div className="icon is-small is-left">
										<FontAwesomeIcon icon={faMicrophone} />
									</div>
								</div>
							</div>
						</div>
					</div>}
					<div className="buttons">
						{!hasGivenPermissions() && <button
							className="button is-link"
							disabled
						>
							<span>Join call now</span>
							<span className="icon is-small">
								<FontAwesomeIcon icon={faRightToBracket} />
							</span>
						</button>}
						{hasGivenPermissions() && <button
							className="button is-link"
							onClick={props.onJoinCall}
						>
							<span>Join call now</span>
							<span className="icon is-small">
								<FontAwesomeIcon icon={faRightToBracket} />
							</span>
						</button>}
						{!hasGivenPermissions() && <button
							className="button is-link is-light"
							disabled
						>
							<span>Join as Bot</span>
							<span className="icon is-small">
								<FontAwesomeIcon icon={faRobot} />
							</span>
						</button>}
						{hasGivenPermissions() && <button
							className="button is-link is-light"
							data-tooltip="Will only broadcast your video/audio"
							onClick={props.onJoinAsBot}
						>
							<span>Join as Bot</span>
							<span className="icon is-small">
								<FontAwesomeIcon icon={faRobot} />
							</span>
						</button>}
					</div>
				</section>
			</div>

			<nav className="navbar is-light is-fixed-bottom">
				<div className="navbar-menu is-active is-flex">
					<div className="navbar-start"></div>

					<div className="navbar-item ">
						{props.videoWillBeActive && <button
							className="button is-info is-rounded"
							data-tooltip="Disable camera"
							onClick={() => props.onChangeVideoWillBeActive(false)}
						>
							<span className="icon">
								<FontAwesomeIcon icon={faCameraAlt} />
							</span>
						</button>}
						{!props.videoWillBeActive && <button
							className="button is-info is-rounded is-light"
							data-tooltip="Enable camera"
							onClick={() => props.onChangeVideoWillBeActive(true)}
						>
							<span className="icon">
								<FontAwesomeIcon icon={faCameraAlt} />
							</span>
						</button>}
					</div>

					<div className="navbar-item ">
						{props.audioWillBeActive && <button
							className="button is-info is-rounded"
							data-tooltip="Mute"
							onClick={() => props.onChangeAudioWillBeActive(false)}
						>
							<span className="icon">
								<FontAwesomeIcon icon={faMicrophone} />
							</span>
						</button>}
						{!props.audioWillBeActive && <button
							className="button is-info is-rounded is-light"
							data-tooltip="Unmute"
							onClick={() => props.onChangeAudioWillBeActive(true)}
						>
							<span className="icon">
								<FontAwesomeIcon icon={faMicrophoneSlash} />
							</span>
						</button>}
					</div>

					<div className="navbar-end"></div>
				</div>
			</nav>
		</>
	);
}
