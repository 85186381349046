import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faCameraAlt, faMicrophoneSlash, faMicrophone, faBedPulse} from '@fortawesome/free-solid-svg-icons';
import "@creativebulma/bulma-tooltip/dist/bulma-tooltip.css";

import ChatView from "./chatView";


// NOTE(sylvain): Should be named "Tab Bar" ? Even if not really tabs.
// cf https://careerfoundry.com/en/blog/ui-design/ui-element-glossary/
// or maybe "Floating Action Button ?" Or really Tab Bar consisting of
// Floating Action Buttons ?
// https://ux.stackexchange.com/questions/117121/is-it-acceptable-to-place-an-action-button-on-a-tab-bar
// https://www.invisionapp.com/inside-design/comprehensive-guide-designing-ux-buttons/
export default function RoomBottomBar(props) {

	const navigate = useNavigate();

	function hangUp() {
		navigate("/home");
	}

	return (
		<nav className="navbar is-light is-fixed-bottom ">
			<div className="navbar-menu is-active is-flex">
				<div className="navbar-start"></div>
				<div className="navbar-item">
					{props.isVideoActive && <button
						className="button is-info is-rounded"
						data-tooltip="Disable camera"
						onClick={() => props.activateVideo(false)}
					>
						<span className="icon">
							<FontAwesomeIcon icon={faCameraAlt} />
						</span>
					</button>}
					{!props.isVideoActive && <button
						className="button is-info is-rounded is-light"
						data-tooltip="Enable camera"
						onClick={() => props.activateVideo(true)}
					>
						<span className="icon">
							<FontAwesomeIcon icon={faCameraAlt} />
						</span>
					</button>}
				</div>

				<div className="navbar-item ">
					{props.isAudioActive && <button
						className="button is-info is-rounded"
						data-tooltip="Mute"
						onClick={() => props.activateAudio(false)}
					>
						<span className="icon">
							<FontAwesomeIcon icon={faMicrophone} />
						</span>
					</button>}
					{!props.isAudioActive && <button
						className="button is-info is-rounded is-light"
						data-tooltip="Unmute"
						onClick={() => props.activateAudio(true)}
					>
						<span className="icon">
							<FontAwesomeIcon icon={faMicrophoneSlash} />
						</span>
					</button>}
				</div>

				<div className="navbar-item ">
					<a className="button is-danger is-rounded" data-tooltip="End call" onClick={() => hangUp()}>
						<span className="icon">
							<FontAwesomeIcon icon={faPhone} />
						</span>
					</a>
				</div>
				
				<div className="navbar-end">
					<div className="navbar-item">
						<ChatView ws={props.ws} />
					</div>
				</div>
			</div>
		</nav>
	);
}
