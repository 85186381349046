import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faUser, faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import UserService from '../services/user-service';


export default function Signup() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [appMessage, setAppMessage] = useState("");
  const [appErrorMessage, setAppErrorMessage] = useState("");

  function handlePasswordChange(event) {
    setPassword(event.target.value);
  }

  function handleUsernameChange(event) {
    setUsername(event.target.value);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setAppErrorMessage("");
    setAppMessage("");
    try {
      await UserService.signup({
        name: username,
        password: password
      });
      setAppMessage("Account created !");
    } catch (err) {
      if (err.response) {
        // most likely an axios error, pray it actually is otherwise it might crash inside the try/catch...
        if (err.response.status >= 500) setAppErrorMessage("Server error");
      } else {
        // any other kind of error ?
        setAppErrorMessage("Something went wrong...");
      }
      console.error(err);
    }
  }

  return (
    <form className="box" onSubmit={handleSubmit}>
      <div className="field">
        <p className="control has-icons-left has-icons-right">
          <input
            className="input"
            name="username"
            type="text"
            placeholder="Username"
            value={username}
            onChange={handleUsernameChange}
          />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faUser} />
          </span>
        </p>
      </div>
      <div className="field">
        <p className="control has-icons-left">
          <input
            className="input"
            name="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={handlePasswordChange}
          />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faLock} />
          </span>
        </p>
      </div>
      <div className="field">
        <p className="control has-icons-left">
          <input
            className="input"
            name="password"
            type="password"
            placeholder="Confirm Password"
            value={password}
            onChange={handlePasswordChange}
          />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faLock} />
          </span>
        </p>
      </div>
      {appMessage && (
      <div className="notification is-success">
        <div className="container has-text-centered">
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faCheck} />
          </span>
          <div className="container">{appMessage}</div>
        </div>
      </div>
      )}
      {appErrorMessage && (
      <div className="notification is-danger">
        <div className="container has-text-centered">
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </span>
          <div className="container">{appErrorMessage}</div>
        </div>
      </div>
      )}
      <div className="control">
        <input
          className="button is-link"
          type="submit"
          value="Create account"
        />
      </div>
    </form>
  );
}
