import { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import QRCode from 'qrcode';

import logo from '../images/LUMYS-logo-couleurs.png';
import AuthService from '../services/auth-service';
import ImageModal from '../components/modalImage';


function NavBar() {
  const navigate = useNavigate();
  const navbarRef = useRef("navRef");
  const userLocalStorage = AuthService.getCurrentUser();
  const [qrModalVisible, setQrModalVisible] = useState(false);
  const [qrCode, setQrCode] = useState('');

  useEffect(() => {
    const url = `${process.env.REACT_APP_LUMYS_API_URL}`;
    QRCode.toDataURL(url, { errorCorrectionLevel: 'M' }, function (err, url) {
      if (err) throw err
      setQrCode(url);
    });
  }, []);

  useEffect(() => {
    if (!userLocalStorage) navigate("/login");
  }, [userLocalStorage]);

  function logout() {
    AuthService.logout();
    navigate("/login");
  }

  function burgerActive(event) {
    event.target.classList.toggle("is-active");
    navbarRef.current.classList.toggle("is-active");
  }

  function toggleQrModal() {
    setQrModalVisible(!qrModalVisible);
  }

  const instanceStyle = {
    background: `${process.env.REACT_APP_INSTANCE_COLOR}`,
  }

  return (
    <nav className="navbar lumys" role="navigation" aria-label="main navigation" style={instanceStyle}>
      <div className="navbar-brand">
        <a className="navbar-item" onClick={() => navigate("/home")}>
          <img src={logo} alt="lumys logo" />
        </a>
        <a className="navbar-item" onClick={toggleQrModal}>
          {/* TODO(sylvain): change from hardcoded url to per-instance url */}
          {/* TODO(oceane) */}
          <img src={qrCode}></img>
          {/*<img src={`${process.env.PUBLIC_URL}/assets/lumys-tech.svg`}></img>*/}
        </a>
        <span
          className="navbar-item has-text-weight-bold has-background-light"
          style={instanceStyle}
        >{process.env.REACT_APP_INSTANCE_NAME}</span>
        <a
          role="button"
          className="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          onClick={burgerActive}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div className="navbar-menu" ref={navbarRef}>
        <div className="navbar-end">
          <div className="navbar-item">
            <span className="tag is-large is-info is-light">{userLocalStorage && userLocalStorage.userName}</span>
          </div>
          {userLocalStorage && (<div className="navbar-item">
            <button className="button is-danger" onClick={logout}>
              <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faSignOutAlt} />
              </span>
              <strong>Log out</strong>
            </button>
          </div>)}
        </div>
      </div>

      <ImageModal 
        active={qrModalVisible}
        src={qrCode}
        onClickOut={toggleQrModal}
      />
    </nav>
  );
}
export default NavBar;
