import { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faFilePdf, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import { MediaListItem } from './media/item';

import "../css/scroll.scss";
import "bulma-list/css/bulma-list.css";


export default function ImageManager(props) {
  const [tabState, setTabState] = useState(['is-active', '', '', '']);
  const [mediaPreview, setMediaPreview] = useState({
    id: '',
    name: '',
    type: '',
  });
  const [filterPattern, setFilterPattern] = useState('');
	const [medias, setMedias] = useState({
		models: [],
		videos : [],
		images: [],
		pdfs: [],
		dicoms: [],
		others: [],
	});

	useEffect(() => {
		setMedias(props.medias);
	}, [props.medias]);

	function displayTab(type_index) {
		let new_tab_state = [...tabState];
		for (let i = 0; i < new_tab_state.length; i++) {
			new_tab_state[i] = "";
		}
		new_tab_state[type_index] = 'is-active';
		setTabState(new_tab_state);
		switch (type_index) {
			case 0:
				setMedias(props.medias);
				break;
			case 1:
				setMedias({images: props.medias.images});
				break;
			case 2:
				setMedias({videos: props.medias.videos});
				break;
			case 3:
				setMedias({dicoms: props.medias.dicoms});
				break;
			case 4:
				setMedias({pdfs: props.medias.pdfs});
				break;
			case 5:
				setMedias({others: props.medias.others});
				break;
		}
	}

	function onSelectMedia(fileId) {
		if (props.mediaSelectedCallback) {
			const propagateToOtherUsers = true;
			props.mediaSelectedCallback(fileId, propagateToOtherUsers);
		}
	}

  function onFilterMediaList(event) {
    setFilterPattern(event.target.value.toLowerCase());
  }

	function filterFileOnName(file) {
		return file.name.toLowerCase().includes(filterPattern);
	}

	return (
		<div className="has-text-centered">
			{mediaPreview.type === 'image' && <img
				style={{maxHeight: '400px'}}
				src={`${process.env.REACT_APP_LUMYS_API_URL}room/${props.room_id}/files/${mediaPreview.id}`}
				alt=""
			/>}
			{mediaPreview.type === 'video' && <video
				style={{maxHeight: '400px'}}
				src={`${process.env.REACT_APP_LUMYS_API_URL}room/${props.room_id}/files/${mediaPreview.id}`}
				controls
				autoPlay
				muted
			/>}
			{mediaPreview.type === 'dicom' && <img
				style={{maxHeight: '400px'}}
				src={`${process.env.REACT_APP_LUMYS_API_URL}room/${props.room_id}/files/${mediaPreview.id}/png`}
				alt=""
			/>}
			<nav className="panel">
				<p className="panel-heading">{mediaPreview.name}</p>
				<p className="panel-tabs">
					<a className={tabState[0]} onClick={(e) => displayTab(0)}>All</a>
					<a className={tabState[1]} onClick={(e) => displayTab(1)}>Images</a>
					<a className={tabState[2]} onClick={(e) => displayTab(2)}>Videos</a>
					<a className={tabState[3]} onClick={(e) => displayTab(3)}>Dicoms</a>
					<a className={tabState[4]} onClick={(e) => displayTab(4)}>PDFs</a>
					<a className={tabState[5]} onClick={(e) => displayTab(5)}>Others</a>
				</p>
				<div className="panel-block">
					<p className="control has-icons-left">
						<input
							className="input is-primary"
							type="text"
							placeholder="Search"
							onChange={onFilterMediaList}
						/>
						<span className="icon is-left">
							<FontAwesomeIcon icon={faSearch} />
						</span>
					</p>
				</div>
				<div className="horizontal-scroll">
					{medias.images && medias.images.filter(filterFileOnName).map((file, index) => (
						<MediaListItem
							key={`image-${index}`}
							type="image"
							file={file}
							room_id={props.room_id}
							onClickPreview={setMediaPreview}
							onClickSelectMedia={onSelectMedia}
						/>
					))}
					{medias.videos && medias.videos.filter(filterFileOnName).map((file, index) => (
						<MediaListItem
							key={`video-${index}`}
							type="video"
							file={file}
							room_id={props.room_id}
							onClickPreview={setMediaPreview}
							onClickSelectMedia={onSelectMedia}
						/>
					))}
					{medias.pdfs && medias.pdfs.filter(filterFileOnName).map((file, index) => (
						<MediaListItem
							key={`pdf-${index}`}
							type="pdf"
							file={file}
							room_id={props.room_id}
						/>
					))}
					{medias.dicoms && medias.dicoms.filter(filterFileOnName).map((file, index) => (
						<MediaListItem
							key={`dicom-${index}`}
							type="dicom"
							file={file}
							room_id={props.room_id}
							onClickPreview={setMediaPreview}
							onClickSelectMedia={onSelectMedia}
						/>
					))}
					{medias.others && medias.others.filter(filterFileOnName).map((file, index) => (
						<MediaListItem
							key={`other-${index}`}
							type="other"
							file={file}
							room_id={props.room_id}
						/>
					))}
        </div>
      </nav>
    </div>
  );
}
