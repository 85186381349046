import React from "react";


function ImageModal(props){
  return (
    <div className={props.active ? 'modal is-active' : 'modal'}>
      <div className="modal-background" onClick={props.onClickOut}></div>
      <div className="modal-content">
        <p className="image is-square">
            <img src={props.src} />
        </p>
      </div>
    </div>
  );
}

export default ImageModal;
