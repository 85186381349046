import React, { useState } from "react";
import { Navigate } from "react-router";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faExclamationTriangle, faLock } from '@fortawesome/free-solid-svg-icons'

import AuthService from "../services/auth-service";


export default function LoginForm() {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userErrorMessage, setUserErrorMessage] = useState("");
  const [appErrorMessage, setAppErrorMessage] = useState("");
  const [user, setUser] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    // Disable the submit button
    setDisabledSubmitButton(true);

    event.preventDefault();
    setUserErrorMessage("");
    setAppErrorMessage("");
    try {
      await AuthService.login(username, password);
      setUser(AuthService.getCurrentUser());

      
      setLoggedIn(true);
    } catch (err: any) { // NOTE(sylvain): yeahhh 'any' again because Errors are apparently limited with Typescript
      if (err.response) {
        // most likely an axios error, pray it actually is otherwise it might crash inside the try/catch...
        if (err.response.status == 403) {
          setUserErrorMessage("Your account has not been activated");
        } else if (err.response.status >= 400 && err.response.status < 500) {
          setUserErrorMessage("Incorrect username or password");
        } else if (err.response.status >= 500) {
          setAppErrorMessage("Server error");
        }
      } else {
        // any other kind of error ?
        setAppErrorMessage("Something went wrong...");
      }
      console.error(err);

      // Enable the submit button
      setDisabledSubmitButton(false);
    }
  }

  return (
    <form className="box" onSubmit={handleSubmit}>
      {loggedIn && <Navigate to="/home" replace={true} />}
      <div className="field">
        <p className="control has-icons-left has-icons-right">
          <input
            className="input"
            name="username"
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faUser} />
          </span>
        </p>
      </div>
      <div className="field">
        <p className="control has-icons-left">
          <input
            className="input"
            name="password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faLock} />
          </span>
        </p>
      </div>
      {userErrorMessage && (
        <div className="notification is-warning">
          <div className="container has-text-centered">
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </span>
            <div className="container">{userErrorMessage}</div>
          </div>
        </div>
      )}
      {appErrorMessage && (
        <div className="notification is-danger">
          <div className="container has-text-centered">
            <span className="icon is-small is-left">
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </span>
            <div className="container">{appErrorMessage}</div>
          </div>
        </div>
      )}
      <div className="control">
        {disabledSubmitButton
          ?
          <progress className="progress is-small is-primary" max="100">5%</progress>
          :
          <input className="button is-link" type="submit" value="Login" />
        }
      </div>
    </form>
  );
}
