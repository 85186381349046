import { useState, useEffect } from "react";
import * as BABYLON from "@babylonjs/core";
import * as GUI from "@babylonjs/gui";
import "@babylonjs/inspector";
import "@babylonjs/loaders";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube } from '@fortawesome/free-solid-svg-icons';

import RoomService from "../services/room-service";
import AuthService from "../services/auth-service";
import arrowLogo from "../images/Arrow.png";
import measureLogo from "../images/Measure.png";
import arrowSelectedLogo from "../images/Arrow-selected.png";
import measureSelectedLogo from "../images/Measure-selected.png";
import fullScreenLogo from "../images/FullScreen.png";

import SceneComponent from "./sceneComponent";


function createUIButton({name, logo, text, topDistance}, onClick) {
	let button;
	if (text && logo) throw new Error("Not supposed to give both text and logo parameters");
	if (text) {
		button = GUI.Button.CreateSimpleButton(name, text);
	} else if (logo) {
		button = GUI.Button.CreateImageOnlyButton(name, logo);
	} else {
		throw new Error("Could not create button with those parameters");
	}
	button.onPointerClickObservable.add(onClick);
	button.top = topDistance;
	// defaults
	button.left = "45%";
	button.width = "8%";
	button.height = "16%";
	button.color = "white";
	button.background = "transparent";
	button.pointerEnterAnimation = () => {
		button.width = "9%";
		button.height = "17%";
	}
	button.pointerOutAnimation = () => {
		button.width = "8%";
		button.height = "16%";
	}
	return button;
}

async function createArrow(name, color, scene) {
	const mesh = await BABYLON.SceneLoader.ImportMeshAsync(
		"",
		`${process.env.PUBLIC_URL}/assets/`,
		"Arrow.glb",
		scene
	);
	mesh.meshes.forEach((element) => {
		const material = new BABYLON.StandardMaterial(
			"createArrow shader" + name,
			scene
		);
		material.diffuseColor = color;
		element.material = material;
	});
	mesh.meshes[0].name = name;
	return mesh.meshes[0];
}

const NO_MODEL = 'no-model';

function ModelSelector(props) {
	function onChange(e) {
		if (props.onChangeModel)
			props.onChangeModel(e.target.value);
	}

	return (<span className="select">
		<select onChange={onChange}>
			<option value={NO_MODEL}>None</option>
			{props.models.map((model) => (
				<option value={model.id} key={model.id}>{model.name}</option>
			))}
		</select>
	</span>);
}

export default function Babylon(props) {
	const url = new URL(window.location);
	const roomId = url.searchParams.get("id");

	const [models, setModels] = useState([]);
	const [currentModelId, setCurrentModelId] = useState();

	const [camera, setCamera] = useState();
	const [currentScene, setCurrentScene] = useState();
	const [currentMesh, setCurrentMesh] = useState();
	const [currentScaleVector, setCurrentScaleVector] = useState(BABYLON.Vector3.One());

	const [canPick, setCanPick] = useState(false);
	const [picker, setPicker] = useState();
	const [pickerModel, setPickerModel] = useState();
	const [mapPickers, setMapPickers] = useState(new Map());

	const [canMeasure, setCanMeasure] = useState(false);
	const [firstMeasure, setFirstMeasure] = useState();
	const [secondMeasure, setSecondMeasure] = useState();
	const [measureIndex, setMeasureIndex] = useState();
	const [line, setLine] = useState();
	const [distance, setDistance] = useState();

	const [posPeerPicker, setPosPeerPicker] = useState();
	const [advancedTexture, setAdvancedTexture] = useState();
	//GUI Button
	const [pointerButton, setPointerButton] = useState();
	const [measureButton, setMeasureButton] = useState();

	const [message, setMessage] = useState();

	function updateAvailableModels() {
		RoomService.getFiles(roomId).then(response => {
			const models = response.data.files
				.filter(f => f.metadata.mimetype.startsWith('model/'))
				.map(f => { return {
					id: f._id,
					name: f.filename,
					filename: f.filename,
				} });
			setModels(models);
		});
	}

	useEffect(() => {
		updateAvailableModels();

		const parseMessage = event => {
			const message = JSON.parse(event.data);
			setMessage(message);
		}
		props.ws.addEventListener('message', parseMessage);

		return () => { props.ws.removeEventListener('message', parseMessage) };
	}, []);

	useEffect(() => {
		if (!message) return;
		switch(message.request) {
			case 'picker-point':
				let data = JSON.parse(message.data);
				// NOTE(sylvain): Storing only ONE posPeerPicker ? Why not one per user ?
				setPosPeerPicker({
					name: data.name,
					x: data.x * currentScaleVector.x,
					y: data.y * currentScaleVector.y,
					z: data.z * currentScaleVector.z,
					faceId: data.faceId,
					modelId: data.modelId,
					nbVertices: data.nbVertices,
				});
				break;
			case 'new-media':
			case 'remove-media':
				// NOTE(sylvain): kind of a hack
				updateAvailableModels();
				break;
			case 'change-model':
				const modelId = message.data;
				setCurrentModelId(modelId);
				break;
		}
	}, [message]);

  useEffect(() => {
    if (picker && canPick) {
      pickPoint();
    } else if (picker && canMeasure) {
      measureDistance();
    }
  }, [picker]);

	useEffect(() => {
		if (!posPeerPicker) return;

		updatePointers(
			posPeerPicker.name,
			new BABYLON.Vector3(posPeerPicker.x, posPeerPicker.y, posPeerPicker.z),
			posPeerPicker.faceId,
			posPeerPicker.modelId,
			posPeerPicker.nbVertices
		);
	}, [posPeerPicker]);

	useEffect(() => {
		const resize = () => currentScene?.getEngine().resize();
		if (props.isActive) {
			window?.addEventListener("resize", resize);
			resize(); // NOTE(sylvain): apparently, babylon still creates huge canvas
			// sometimes... (36096x20288 anyone ?) I just don't understand why...
			loadModel(currentModelId);
		} else {
			window?.removeEventListener("resize", resize);
			currentScene?.getEngine().setSize(240, 135); // NOTE(sylvain): we don't
			// want a fucking 8K canvas when babylon is unable to get the size of the
			// canvas because it's not visible and has a size of 0px...
		}
	}, [props.isActive]);

	useEffect(() => {
		loadModel(currentModelId);
	}, [currentModelId]);

	function onSceneReady(scene) {
		// NOTE(sylvain): We may want a different camera, dragging with the mouse
		// should give the illusion that we're rotating the object, not moving the
		// camera around (i.e. lights should move when rotating). Also, it's
		// difficult to zoom on something that's not in the middle of the model
		// when this one is elongated, we need a way to move the camera point of
		// rotation.
		const camera = new BABYLON.ArcRotateCamera(
			"Camera",
			-Math.PI / 2,
			Math.PI / 2,
			100.0,
			new BABYLON.Vector3(0, 0, 0),
			scene
		);
		camera.lowerBetaLimit = null;
		camera.upperBetaLimit = null;
		camera.allowUpsideDown = true;
		camera.noRotationConstraint = true;

		const canvas = scene.getEngine().getRenderingCanvas();

		// This attaches the camera to the canvas
		camera.attachControl(canvas);
		camera.panningSensibility = 0;
		new BABYLON.HemisphericLight("light1", new BABYLON.Vector3(1, 1, 0), scene);
		new BABYLON.PointLight("light2", new BABYLON.Vector3(0, 1, -1), scene);
		const advanced = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");
		setAdvancedTexture(advanced);
		spawnPickers(scene);
		setCurrentScene(scene);
		setCamera(camera);
	};

	function createUI() {
		let fullscreenButton = createUIButton({
			name: "fullscreen",
			logo: fullScreenLogo,
			topDistance:"40%"
		}, () => {
			const engine = currentScene.getEngine();
			engine.switchFullscreen(false);
			engine.resize();
		});

		let pointerButton = createUIButton({
			name: "pointer",
			logo: arrowLogo,
			topDistance: "20%"
		}, () => {
			pointerButton.image.source = arrowSelectedLogo;
			setCanPick(true);
		});
		setPointerButton(pointerButton);

		let mesureButton = createUIButton({
			name: "mesure",
			logo: measureLogo,
			topDistance: "0%"
		}, () => {
			mesureButton.image.source = measureSelectedLogo;
			setMeasureIndex(2);
			setCanMeasure(true);
		});
		setMeasureButton(mesureButton);

		let shareViewButton = createUIButton({
			name: "view",
			text: "Share Viewpoint",
			topDistance: "-20%"
		}, sendIncidenceAngle);

		advancedTexture.addControl(fullscreenButton);
		advancedTexture.addControl(pointerButton);
		advancedTexture.addControl(mesureButton);
		advancedTexture.addControl(shareViewButton);
  }

	function changeModel(modelId) {
		setCurrentModelId(modelId);
		props.ws.send(
			JSON.stringify({
				request: 'change-model',
				roomId: roomId,
				data: modelId,
			})
		);
	}

	function loadModel(modelId) {
		if (!props.isActive) return;
		if (!currentScene) return;

		if (!modelId || modelId === NO_MODEL) {
			clearScene(currentScene);
			return;
		}

		currentScene.getEngine().resize();
		BABYLON.SceneLoader.ShowLoadingScreen = false;
		currentScene.getEngine().displayLoadingUI();

		RoomService.getFile(roomId, modelId).then((response) => {
			const modelBlob = new Blob([response.data]);
			// TODO(sylvain): would be nice to have real model name :/
			const modelFile = new File([modelBlob], "pouet.glb");

			currentScene.getEngine().hideLoadingUI();

			BABYLON.SceneLoader.Append("file:", modelFile, currentScene, scene => {
				clearScene(currentScene);
				createUI();

				const findNewMesh = (scene) => {
					for (const mesh of scene.meshes) {
						if (mesh.parent?.name === "__root__") {
							return mesh;
						}
					}
				}

				const newMesh = findNewMesh(scene);
				newMesh.name = modelId; // NOTE(sylvain): rewriting name which was
				// stored in the glb/gltf with the actual model file ID, may cause
				// problems ? :/

				// NOTE(sylvain): uniform scaling, get scaling for placing pickers
				// later and then actually rescale mesh.
				const scaleVector = BABYLON.Vector3.One();
				scaleVector.x = 1 / newMesh.scaling.x;
				scaleVector.y = 1 / newMesh.scaling.y;
				scaleVector.z = 1 / newMesh.scaling.z;
				setCurrentScaleVector(scaleVector);
				newMesh.scaling.x = 1;
				newMesh.scaling.y = 1;
				newMesh.scaling.z = 1;
				// NOTE(sylvain): update camera min and max zoom
				// Justine says: "Ça c'est une feature incroyable"
				const meshBoudingSphere = newMesh.getBoundingInfo().boundingSphere;
				const maxRadius = meshBoudingSphere.radius * 8;
				const maxZoomRatio = 100;
				camera.upperRadiusLimit = maxRadius;
				camera.lowerRadiusLimit = maxRadius / maxZoomRatio;
				camera.radius = maxRadius;
				// NOTE(sylvain): make zoom speed consistent
				camera.inputs.attached.mousewheel.wheelPrecision = maxZoomRatio / meshBoudingSphere.radius;

				setCurrentMesh(newMesh);

				// NOTE(sylvain): basically a http get request...
				props.ws.send(JSON.stringify({
					request: "pointers-history",
					roomId: roomId,
				}));
			});
		});

		// NOTE(sylvain): Cf le code source parce que la doc de Babylonjs est aussi INCOMPLETE:
		// packages/dev/core/src/Inputs/scene.inputManager.ts
		// packages/dev/core/src/scene.ts
		// En gros, quand onPointerUp() du Input Manager est declanché.
		currentScene.onPointerPick = (_event, pickedResult) => { setPicker(pickedResult) };
	}

	async function updatePointers(name, position, faceId, modelId, nbVertices) {
		if (
			!currentMesh ||
			modelId !== currentMesh.name || // NOTE(sylvain): the "name" is the model
			// id we had to overwrite from the name contained in the glb/gltf file
			// to the actual ID of the model.
			nbVertices !== currentMesh.getTotalVertices()
		) return;

		if (name === AuthService.getCurrentUser().userName) {
			// NOTE(sylvain): our own pointer, deal with it and early return.
			setPickerPosition(position, pickerModel, faceId);
			return;
		}

		if (!mapPickers.has("Arrow-" + name)) {
			const newArrow = await createArrow(
				"Arrow-" + name,
				new BABYLON.Color3(0.0, 1.0, 0.0),
				currentScene
			);
			const label = new GUI.TextBlock();
			label.text = name;
			label.color = "503232";
			label.outlineWidth = 2;
			label.outlineColor = "Grey";
			label.isHitTestVisible = false;
			advancedTexture.addControl(label);
			label.linkWithMesh(newArrow);
			label.linkOffsetY = -50;
			setPickerPosition(position, newArrow, faceId);
			setMapPickers(new Map(mapPickers.set("Arrow-" + name, newArrow)));
		} else {
			const arrow = mapPickers.get("Arrow-" + name);
			setPickerPosition(position, arrow, faceId);
		}
  }

  async function spawnPickers(scene) {
    const setMeshInvisible = (mesh) => { mesh.isVisible = false; };

    const arrowIncidenceAngle = await createArrow(
      "incidenceAngle",
      new BABYLON.Color3(1.0, 0.0, 0.0),
      scene
    );
    arrowIncidenceAngle.isVisible = false;
    arrowIncidenceAngle.getChildMeshes().forEach(setMeshInvisible);

    setPickerModel(arrowIncidenceAngle);
    const firstPicker = await createArrow(
      "firstPicker",
      new BABYLON.Color3(0.0, 0.0, 1.0),
      scene
    );
    firstPicker.isVisible = false;
    firstPicker.getChildMeshes().forEach(setMeshInvisible);

    setFirstMeasure(firstPicker);
    const secondPicker = await createArrow(
      "secondPicker",
      new BABYLON.Color3(0.0, 0.0, 1.0),
      scene
    );
    secondPicker.isVisible = false;
    secondPicker.getChildMeshes().forEach(setMeshInvisible);
    setSecondMeasure(secondPicker);
  }

  function measureDistance() {
    if (picker.hit && canMeasure) {
      if (measureIndex === 2) {
        setPickerPosition(picker.pickedPoint, firstMeasure, picker.faceId);
        setMeasureIndex(1);
        if (line) {
          line.dispose();
          secondMeasure.isVisible = false;
        }
        if (distance) {
          distance.dispose();
        }
      } else if (measureIndex === 1) {
        setPickerPosition(picker.pickedPoint, secondMeasure, picker.faceId);
        let positions = [];
        positions.push(firstMeasure.position);
        positions.push(picker.pickedPoint);
        const options = {
          points: positions, //vec3 array,
          updatable: false,
        };
        let lines = BABYLON.MeshBuilder.CreateLines(
          "lines",
          options,
          currentScene
        );
        lines.color = BABYLON.Color3.FromHexString("#4fab56");
        setLine(lines);

        let length = firstMeasure.position
          .subtract(picker.pickedPoint)
					.divide(currentScaleVector)
          .length();

        if (length < 10.0) {
          length = length.toPrecision(3);
        } else {
          length = length.toPrecision(4);
        }
        const label = new GUI.TextBlock();
        label.text = length + " (mm)";
        label.color = "#4fab56";
        label.fontSize = 30;
        label.linkOffsetY = -50;
        label.background = "transparent";
        label.isHitTestVisible = false;
        setDistance(label);
        setMeasureIndex(0);
        setCanMeasure(false);
        measureButton.image.source = measureLogo;
        advancedTexture.addControl(label);
        label.linkWithMesh(lines);
        label.isFocusInvisible = true;
      }
    }
  }

	function pickPoint() {
		if (picker.hit && canPick) {
			pointerButton.image.source = arrowLogo;
			const worldMatrix = picker.pickedMesh.getWorldMatrix();
			BABYLON.Vector3.TransformCoordinates(picker.pickedPoint, worldMatrix);
			setPickerPosition(picker.pickedPoint, pickerModel, picker.faceId);
			props.ws.send(
				JSON.stringify({
					request: 'point-model',
					roomId: roomId,
					data: JSON.stringify({
						x: picker.pickedPoint.x / currentScaleVector.x,
						y: picker.pickedPoint.y / currentScaleVector.y,
						z: picker.pickedPoint.z / currentScaleVector.z,
						faceId: picker.faceId,
						// TODO(sylvain): remove trusting the client... use Auth model server side.
						name: AuthService.getCurrentUser().userName,
						modelName: currentModelId, // NOTE(sylvakn): legacy / compatibility
						modelId: currentModelId,
						nbVertices: currentMesh.getTotalVertices(),
					}),
				})
			);
		}
		setCanPick(false);
	}

  function setPickerPosition(position, picker, faceId) {
    picker.position = position;
    picker.getChildMeshes().forEach((mesh) => {
      mesh.isVisible = true;
    });
    const normal = currentMesh.getFacetNormal(faceId);
    picker.alignWithNormal(normal);
  }

	function clearScene(scene) {
		currentMesh?.dispose();

		scene.meshes.forEach((mesh) => {
			if (mapPickers.has(mesh.name)) mesh.dispose();
		});
		if (line) {
			line.dispose();
			setDistance();
		}
		advancedTexture.getChildren().forEach(child => { child.dispose(); });
		const setMeshInvisible = mesh => { mesh.isVisible = false; }
		pickerModel.getChildMeshes().forEach(setMeshInvisible);
		firstMeasure.getChildMeshes().forEach(setMeshInvisible);
		secondMeasure.getChildMeshes().forEach(setMeshInvisible);
		setMapPickers(new Map(mapPickers.clear()));
	}

  function sendIncidenceAngle() {
    const camPosition = camera.position.clone();
    let upVector = BABYLON.Vector3.TransformCoordinates(
      camera.upVector,
      camera.computeWorldMatrix().clone()
    );
    upVector = upVector.subtract(camera.position);
    props.ws.send(
      JSON.stringify({
        request: "incidence-angle",
        roomId: roomId,
        data: JSON.stringify({
          x: camPosition.x,
          y: camPosition.y,
          z: camPosition.z,
          upx: upVector.x,
          upy: upVector.y,
          upz: upVector.z,
        }),
      })
    );
  }

  return (<div className="container">
		<SceneComponent antialias adaptToDeviceRatio={true} onSceneReady={onSceneReady} id="my-canvas" />
		<div className="text-has-centered">
			<p className="control has-icons-left">
				<ModelSelector models={models} onChangeModel={changeModel}/>
				<span className="icon is-small is-left">
					<FontAwesomeIcon icon={faCube} />
				</span>
			</p>
		</div>
	</div>);
}
