import axios_instance from './base';


const AuthService = {
  login: async (username, password) => {
    const response = await axios_instance.post(
      `/user/login`,
      { name: username, password: password },
    );

    if (!response.data.error) { // NOTE(sylvain): Not very resilient ? Should better test for error ?
      const user = JSON.stringify(response.data);
      localStorage.setItem("user", user);
      return user;
    } else {
      return null;
    }
  },

  logout: async() => {
    await axios_instance.post(`/user/logout`);
    localStorage.removeItem("user");
  },

  getCurrentUser: () => {
    return JSON.parse(localStorage.getItem("user"));
  },

  canEditRoom: (room) => {
    const myId = AuthService.getCurrentUser().userId;
    let editors = new Set(room.editors);
    return AuthService.isOwnRoom(room) || editors.has(myId);
  },

  isOwnRoom: (room) => {
    const myId = AuthService.getCurrentUser().userId;
    return room.created_by == myId;
  },
}

export default AuthService;
