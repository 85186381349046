import React from "react";

import LargeDisplay from "./largedisplay";
import Babylon from "./babylon";
import MediaDisplay from "./mediaDisplay";
import HololensDisplay from "./hololensDisplay";



export default function Proctoring(props) {
  return (
    <div className="container is-max-widescreen">
      <div className="tile is-ancestor">
        <div className="tile is-vertical">
          <div className="tile is-parent is-vertical">
            <div className="tile is-child">
              <LargeDisplay ws={props.ws} media={props.videoLargeDisplay}/>
            </div>
            <div className="tile is-child">
              <HololensDisplay media={props.videoHololens} ws = {props.ws}/>
            </div>
          </div>
        </div>
        <div className="tile is-vertical">
          <div className="tile is-parent is-vertical">
            <div className="tile is-child">
              <Babylon ws={props.ws} isActive={props.isActive} />
            </div>
            <div className="tile is-child">
              <MediaDisplay ws={props.ws} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
