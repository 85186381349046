import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import RoomService from "../services/room-service";
import UserService from "../services/user-service";


function RoomForm(props) {
  const { id } = useParams();
  const [name, setName] = useState(props.room.name);
  const [createdBy, setCreatedBy] = useState("?");
  const [originalName, setOriginalName] = useState(props.room.name);
  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    console.log(props.room.created_by);
    UserService.getUser(props.room.created_by).then((res) => {
      setCreatedBy(res.data.user.name);
    });
  }, []);

  async function submitForm(event) {
    event.preventDefault();
    setProcessing(true);
    const response = await RoomService.patchRoom(id, { name: name });
    // TODO(sylvain): handle errors from service response
    setOriginalName(name);
    if (props.onChangeCallback) props.onChangeCallback(name);
    setProcessing(false);
  }

  function hasChanged() {
    return name != originalName;
  }

  function getInputFieldClass() {
    return error ? "input is-danger" : "input";
  }

  return (
    <form onSubmit={submitForm}>
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Room name</label>
        </div>
        <div className="field-body">
          <div className="field has-addons">
            <div className="control">
              <input
                className={getInputFieldClass()}
                type="text"
                placeholder="April Intervention Demonstration"
                name="roomname"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="control">
              {processing && <button className="button" disabled>
                <span className="icon">
                  <FontAwesomeIcon icon={faSpinner} />
                </span>
              </button>}
              {!processing && hasChanged() && <button className="button is-success" onClick={submitForm}>
                Rename
              </button>}
              {!processing && !hasChanged() && <button className="button is-success" disabled>
                Rename
              </button>}
            </div>
            {error && <p className="help is-danger">
              {error}
            </p>}
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Created by</label>
        </div>
        <div className="field-body">
          <div className="control">
            <input className="input" type="text" placeholder={createdBy} disabled />
          </div>
        </div>
      </div>
    </form>
  );
}

export default RoomForm;
